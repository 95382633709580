import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import TextInput from "../../common/inputElements/TextInput";
import Card from "../../common/cards/Card";
import { useDispatch } from "react-redux";
import { showToast } from "../../redux/actions/toastActions";
import { login } from "../../redux/actions/userActions";
import { USER_LOGIN_SUCCESS } from "../../redux/constants/userConstants";

function Login() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: {}, mode: "onChange" });

  async function onSubmit(data) {
    let mongoId = data?.mongoId?.trim();
    if (mongoId?.length !== 24) {
      dispatch(
        showToast({
          message: "Please enter your Id ",
          category: "danger",
        })
      );
    } else {
      setLoading(true);
      axios({
        method: "GET",
        url: `${process.env.REACT_APP_SERVER_URL_LEADS}/user/login/exhibitor/${mongoId}`,
      })
        .then(async (response) => {
          console.log(response);

          localStorage.setItem("accessToken", response.data.accessToken);
          localStorage.setItem("refreshToken", response.data.refreshToken);
          dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: {
              ...response.data.user,
              accessToken: response.data.accessToken,
              refreshToken: response.data.refreshToken,
            },
          });
          navigate("/events");
          setLoading(false);
        })
        .catch((error) => {
          dispatch(
            showToast({
              message: "Wrong Object Id",
              category: "danger",
            })
          );
          setLoading(false);
        });
    }
  }

  return (
    <>
      {/* {loading && <Loader />} */}
      <div
        className={`grid justify-center content-center w-full sm:max-w-[1280px] mx-auto h-[calc(100vh-58px)]`}
      >
        <Card>
          <form
            className="max-w-[340px] flex flex-col gap-[20px]"
            onSubmit={handleSubmit(onSubmit)}
          >
            <p className="text-[22px] font-[700] block text-left mb-[10px]">
              Login to Warpbay!!
            </p>
            <div>
              <TextInput
                type="text"
                id="mongoId"
                required
                label="Enter your id"
                register={register}
                mb="0"
                minLength={16}
                maxLength={16}
              />
            </div>
            <input
              type="submit"
              value={loading ? "Loading..." : `Login`}
              className="primary_submit"
              disabled={loading}
            />
            <p className="text-[13px] font-[500]">
              By continuing you agree to the{" "}
              <span className="text-[#a55eea]">Term of Service</span> and{" "}
              <span className="text-[#a55eea]">Privacy Policy</span>
            </p>
          </form>
        </Card>
        {/* <ScanIcon /> */}
      </div>
    </>
  );
}

export default Login;
